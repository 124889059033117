<template>
  <div>
    <div class="logo">
      <img
        src="https://t4greenhaven.com/wp-content/uploads/2020/05/t4-logowhite-65x65.png"
        width="65"
        class="wp-post-image wp-image-19"
        alt="t4 logowhite"
        srcset="
          https://t4greenhaven.com/wp-content/uploads/2020/05/t4-logowhite-65x65.png    65w,
          https://t4greenhaven.com/wp-content/uploads/2020/05/t4-logowhite-300x300.png 300w,
          https://t4greenhaven.com/wp-content/uploads/2020/05/t4-logowhite-150x150.png 150w,
          https://t4greenhaven.com/wp-content/uploads/2020/05/t4-logowhite-768x768.png 768w,
          https://t4greenhaven.com/wp-content/uploads/2020/05/t4-logowhite-80x80.png    80w,
          https://t4greenhaven.com/wp-content/uploads/2020/05/t4-logowhite-45x45.png    45w,
          https://t4greenhaven.com/wp-content/uploads/2020/05/t4-logowhite.png         900w
        "
        sizes="(max-width: 65px) 100vw, 65px"
      />
      <h1>T4 TEA</h1>
    </div>
    <nav>
      <div class="pclogo" style="align-items: center">
        <img
          style="width: 3.9rem; height: 3.9rem"
          src="https://t4greenhaven.com/wp-content/uploads/2020/05/t4-logowhite-65x65.png"
          width="65"
          class="wp-post-image wp-image-19"
          alt="t4 logowhite"
          srcset="
            https://t4greenhaven.com/wp-content/uploads/2020/05/t4-logowhite-65x65.png    65w,
            https://t4greenhaven.com/wp-content/uploads/2020/05/t4-logowhite-300x300.png 300w,
            https://t4greenhaven.com/wp-content/uploads/2020/05/t4-logowhite-150x150.png 150w,
            https://t4greenhaven.com/wp-content/uploads/2020/05/t4-logowhite-768x768.png 768w,
            https://t4greenhaven.com/wp-content/uploads/2020/05/t4-logowhite-80x80.png    80w,
            https://t4greenhaven.com/wp-content/uploads/2020/05/t4-logowhite-45x45.png    45w,
            https://t4greenhaven.com/wp-content/uploads/2020/05/t4-logowhite.png         900w
          "
          sizes="(max-width: 65px) 100vw, 65px"
        />
      </div>
      <a href="">HOME</a>
      <a href="https://dinfo.me/t4oakland/index.php/welcome/menu">ORDER/MENU</a>
      <a href="https://dinfo.me/t4oakland/index.php/welcome/menu">CART</a>
    </nav>
    <router-view />
  </div>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  background-color: var(--bgcolor-primary);
  display: flex;
  padding: 0 2.2rem;
  align-items: center;
  a {
    font-weight: bold;
    color: #fff;
    font-size: 1.5rem;
    padding: 1.15rem 0rem;
    text-decoration: none;
    margin: 0 0.8rem;
    &.router-link-exact-active {
      // color: #42b983;
      border-bottom: 3px solid #1d736c;
      border-radius: 0.2rem;
    }
  }
}
.logo {
  background-color: var(--bgcolor-primary);
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  padding: 10px;
  font-size: 1.5rem;
  font-family: Arial;
  font-weight: bold;
  h1 {
    margin: 0;
    font-size: 1.5rem;
  }
  img {
    margin-right: 10px;
    width: 2.2rem;
    height: 2.25rem;
  }
}

.pclogo {
  display: none;
}
@media screen and (min-width: 500px) {
  .logo {
    display: none;
  }
  .pclogo {
    display: flex;
  }
  a {
    &.router-link-exact-active {
      // color: #42b983;
      background: #1d736c;
      padding-left: 1.1rem;
      padding-right: 1.1rem;
    }
  }
}
</style>
